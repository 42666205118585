<template>
    <div>
        <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
            <CalculatorSelect
                :form="form"
                label="disabilityLevel"
                :data="disabilityLevel"
                font="伤残等级"
            ></CalculatorSelect>
            <CalculatorInputNumber
                :form="form"
                label="salary"
                suffix="元"
                font="月平均工资"
            ></CalculatorInputNumber>
            <CalculatorInputNumber
                :form="form"
                label="hospitalization"
                suffix="天"
                font="住院天数"
            ></CalculatorInputNumber>
            <CalculatorInputNumber
                :form="form"
                label="downtimePaymentPeriod"
                suffix="月"
                font="停工留薪期"
            ></CalculatorInputNumber>
            <CalculatorInputNumber
                :form="form"
                label="medicalFees"
                suffix="元"
                font="医疗费"
            ></CalculatorInputNumber>
            <CalculatorInputNumber
                :form="form"
                label="auxiliaryInstrumentFee"
                suffix="元"
                font="辅助器具费"
            ></CalculatorInputNumber>
        </a-form-model>
        <ButtonView @start="start" @reset="reset"></ButtonView>
        <Info></Info>
        <CalculatorResultClassification :result="result"></CalculatorResultClassification>
    </div>
</template>

<script>
    import CalculatorSelect from '@/components/PC/calculator/calculator-select.vue'
    import CalculatorInputNumber from '@/components/PC/calculator/calculator-inputNumber.vue'
    import ButtonView from '@/components/PC/calculator/button-view.vue'
    import CalculatorResultClassification from '@/components/PC/calculator/calculator-result-classification.vue'
    import Info from '@/components/PC/calculator/info.vue'
    import { disabilityLevel } from '../data/disabilityLevel'
    import { intervalCalculation } from './intervalCalculation'
    export default {
        name: 'injuryOnTheJob',
        components: {
            CalculatorSelect,
            ButtonView,
            Info,
            CalculatorInputNumber,
            CalculatorResultClassification,
        },
        data() {
            return {
                labelCol: { span: 5 },
                wrapperCol: { span: 17 },
                form: {
                    disabilityLevel: 10,
                    salary: '',
                    hospitalization: '',
                    downtimePaymentPeriod: '',
                    medicalFees: '',
                    auxiliaryInstrumentFee: '',
                },
                disabilityLevel,
                result: [],
            }
        },
        methods: {
            start(callback) {
                for (let item in this.form) {
                    if (!this.form[item]) {
                        this.form[item] = 0
                    } else {
                        this.form[item] = Number(this.form[item])
                    }
                }

                let data = disabilityLevel.filter((item) => {
                    return item.id == this.form.disabilityLevel
                })

                this.result = intervalCalculation(this.form, data[0])
                this.result.splice(0, 0)
                callback()
            },

            reset() {
                this.form = {
                    disabilityLevel: 10,
                    salary: '',
                    hospitalization: '',
                    downtimePaymentPeriod: '',
                    medicalFees: '',
                    auxiliaryInstrumentFee: '',
                }
                this.result = []
                this.result.splice(0, 0)
            },
        },
    }
</script>

function intervalCalculation(
    {
        disabilityLevel,
        salary,
        hospitalization,
        downtimePaymentPeriod,
        medicalFees,
        auxiliaryInstrumentFee,
    },
    { disabilityAllowance, disabilitySubsidy }
) {
    let result = [
        {
            name: '一次性伤残补助金：',
            money: salary * disabilitySubsidy,
        },
        {
            name: '伤残津贴：',
            money: Number((salary * disabilityAllowance).toFixed(2)),
        },
        {
            name: '住院伙食补助费：',
            money: Number((hospitalization * 23.803).toFixed(2)),
        },
        {
            name: '停工留薪期工资：',
            money: salary * downtimePaymentPeriod,
        },
        {
            name: '医疗费：',
            money: medicalFees,
        },
        {
            name: '辅助器具费：',
            money: auxiliaryInstrumentFee,
        },
        {
            name: '总计：',
            money: 0,
        },
    ]

    let sum = 0
    result.forEach((element) => {
        sum = sum + element.money
    })

    result[6].money = sum

    if (disabilityLevel >= 7) {
        result.splice(1, 1)
    }

    return result
}

export { intervalCalculation }
